.drawer-menu {
  .ant-drawer-content {
    background: #61aa71;
  }

  .ant-drawer-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.drawer-menu-block {
  a {
    display: flex;
    color: #fff;
    font-size: 16px;
    padding: 10px;
  }

  a:hover {
    opacity: 0.7;
  }
}
